<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-md-12">
                <div class="card">
                    <ValidationObserver v-slot="{ passes }">
                        <div class="header">
                            <h2>User Management</h2>
                        </div>
                        <div class="body">
                            <form @submit.prevent="passes(userSubmitData)" id="basic-form">
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <ValidationProvider name="first_name" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !user.first_name,'success':user.first_name}">
                                                <span class="float-label">
                                                    <input type="text" v-model="user.first_name" class="form-control" id="first_name" placeholder="First Name*">
                                                    <label for="first_name" class="control-label">First Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !user.first_name"></i>
                                                    <i class="fa fa-check" v-if="user.first_name"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="last_name" rules="required" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !user.last_name,'success':user.last_name}">
                                                <span class="float-label">
                                                    <input type="text" v-model="user.last_name" class="form-control" id="last_name" placeholder="Last Name*">
                                                    <label for="last_name" class="control-label">Last Name*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !user.last_name"></i>
                                                    <i class="fa fa-check" v-if="user.last_name"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="business_name" rules="required" v-slot="{ errors }">
                                             <div class="form-group form-select single-multiselect select-option-custom" :class="{'errors': errors[0],'success':user.businessname && !errors[0]}">
                                                <span class="float-label">
                                                <label for="functions">Select Business Name*</label>
                                                    <multiselect 
                                                        placeholder=""
                                                        v-model="user.businessname" 
                                                        :options="businessname_options" 
                                                        :show-labels="false"
                                                        label="category" 
                                                        track-by="category">
                                                        <template slot="option" slot-scope="props">
                                                            <div>
                                                                <span :class="props.option.isType"> 
                                                                    <span v-if="props.option.isType == 'child'">-</span>
                                                                    {{props.option.category}} 
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.businessname && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="contact_email" rules="required|email" v-slot="{ errors}">
                                            <div class="form-group" :class="{'errors': errors.length && !user.contact_email || errors[0],'success':user.contact_email && !errors[0]}">
                                                <span class="float-label">
                                                    <input type="email" v-model="user.contact_email" class="form-control" name="email1" id="contact_email" placeholder="Contact Email Address*">
                                                    <label for="contact_email" class="control-label">Contact Email Address*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && !user.contact_email || errors[0] "></i>
                                                    <i class="fa fa-check" v-if="user.contact_email && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="mobile_number" rules="required" v-slot="{ errors }">
                                            <div class="form-group" :class="{'errors': errors[0],'success':user.mobile_number && !errors[0]}">
                                                <span class="float-label">
                                                    <input type="text" class="form-control" id="mobile_number" v-model="user.mobile_number" placeholder="Contact Mobile Number">
                                                    <label for="mobile_number" class="control-label">Contact Mobile Number*</label>
                                                    <div class="line"></div>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.mobile_number && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-md-6 col-12">
                                        <ValidationProvider name="select_country" rules="required" v-slot="{ errors}">
                                            <div class="country-dropdown">
                                                <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.country.length != 0  && !errors[0]}">
                                                    <span class="float-label">
                                                    <label for="Country">Select Country*</label>
                                                        <multiselect
                                                            placeholder=""
                                                            v-model="user.country" 
                                                            :show-labels="false" 
                                                            :options="countrys_options" 
                                                            :searchable="false"
                                                            :multiple="true" 
                                                            :close-on-select="false" 
                                                            :clear-on-select="false" 
                                                            label="name" 
                                                            track-by="name"  
                                                            :limit="0"
                                                            :limit-text="displayText"
                                                            @select="isCheckedInput"
                                                            @remove="isCheckedInput">
                                                            <template slot="beforeList">
                                                                <span class="multiselect__option" @click="selectAllChanged">
                                                                    <div class="option__desc">
                                                                        <span class="option__title">All</span>
                                                                    </div>
                                                                    <div class="multiselect__element multiselect-all multi-drop">
                                                                        <div class="control-inline fancy-checkbox" for="isChecked">
                                                                            <input type="checkbox" id="checkbox2" name="checkbox2" :checked="isAllChecked">
                                                                            <span for="checkbox2"></span>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </template>
                                                            <template slot="option" slot-scope="props">
                                                                <span :class="props.option.flagClass"></span>
                                                                <div class="option__desc">
                                                                    <span class="option__title">{{ props.option.name }}</span>
                                                                </div>
                                                                <div class="multiselect__element multiselect-all multi-drop">
                                                                    <div class="control-inline fancy-checkbox" for="isChecked">
                                                                        <input type="checkbox" id="isChecked" name="isChecked" :checked="props.option.isChecked == true">
                                                                        <span for="isChecked"></span>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </multiselect>  
                                                        <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                        <i class="fa fa-check" v-if="user.country.length != 0 && !errors[0]"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="functions" rules="required" v-slot="{ errors }">
                                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.function_selected && !errors[0]}">
                                                <span class="float-label">
                                                <label for="functions">Select Function*</label>
                                                    <multiselect
                                                        placeholder=""
                                                        v-model="user.function_selected"
                                                        :multiple="true"
                                                        :searchable="false"
                                                        :options="function_options">
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.function_selected && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="roles" rules="required" v-slot="{ errors }">
                                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.roles_selected && !errors[0]}">
                                                <span class="float-label">
                                                    <label for="roles">Select Roles*</label>
                                                    <multiselect
                                                        placeholder=""
                                                        v-model="user.roles_selected"
                                                        :multiple="true"
                                                        :searchable="false"
                                                        :options="roles_options">
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.roles_selected && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="services" rules="required" v-slot="{ errors }">
                                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.services_selected && !errors[0]}">
                                                <span class="float-label">
                                                    <label for="Services">Select Services*</label>
                                                    <multiselect
                                                        placeholder=""
                                                        v-model="user.services_selected"
                                                        :multiple="true"
                                                        :searchable="false"
                                                        :options="services_options">
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.services_selected && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="access" rules="required" v-slot="{ errors }">
                                            <div class="form-group form-select multiselect-grp" :class="{'errors': errors[0],'success':user.access_selected && !errors[0]}">
                                                <span class="float-label">
                                                    <label for="Access">Select Access Type*</label>
                                                    <multiselect
                                                        placeholder=""
                                                        v-model="user.access_selected"
                                                        :multiple="true"
                                                        :searchable="false"
                                                        :options="access_type_options">
                                                    </multiselect>
                                                    <i class="fa fa-times" v-if="errors.length && errors[0]"></i>
                                                    <i class="fa fa-check" v-if="user.access_selected && !errors[0]"></i>
                                                </span>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-md-row justify-content-center">
                                    <b-button variant="secondary btn-mw" size="lg">Cancel</b-button>
                                    <b-button type="submit" variant="primary ml-md-1 mt-2 mt-md-0 btn-mw" size="lg">Submit</b-button>
                                </div>
                            </form>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
const { ValidationObserver,ValidationProvider} = require('vee-validate')
import "@/plugins/vee-validate";
export default {
    name: "AddUserComponent",
    components: {
        ValidationObserver,
        ValidationProvider,
        Multiselect: () => import('vue-multiselect'),
        BreadCrumb:() => import('@/components/BreadCrumb.vue')
    },
    data: () => ({
        isAllChecked:false,
        function_options: ['Admin', 'Tech', 'HR', 'Risk', 'Compliance', 'Finance', 'Sales', 'Service', 'Teller','allowEmpty'],
        roles_options:['Owner', 'Admin', 'Manager', 'User', 'Teller'],
        services_options:['Dashboard', 'Transactions', 'User Management'],
        access_type_options:['Read', 'Write', 'Edit', 'Full'],
        businessname_options:[
            {
                id: 1,
                parentId: "",
                isType: "parent",
                category:'Company A',
            },
            {
                id: 2,
                parentId: 1,
                isType: "child",
                category:'Sub Company A-a',
            },
            {
                id: 3,
                parentId: 1,
                isType: "child",
                category:'Sub Company A-b',
            },
            {
                id: 4,
                parentId: "",
                isType: "parent",
                category:'Company B',
            },
            {
                id: 5,
                parentId: 4,
                isType: "child",
                category:'Sub Company B-a',
            },
            {
                id: 6,
                parentId: 4,
                isType: "child",
                category:'Sub Company B-b',
            }
        ],
        countrys_options: [
            { id:0,name: 'India', flagClass:"flag-icon flag-icon-in fi-xl fi-round",isChecked:false},
            { id:1,name: 'United Kingdom', flagClass: "flag-icon flag-icon-gb fi-xl fi-round",isChecked:false},
            { id:2,name: 'France' , flagClass: "flag-icon flag-icon-fr fi-xl fi-round",isChecked:false},
            { id:3,name: 'United States', flagClass: "flag-icon flag-icon-us fi-xl fi-round",isChecked:false},
        ],

        user: {
            first_name:'',
            last_name:'',
            country: [],
            contact_email:'',
            mobile_number:'',
            businessname: null,
            function_selected: null,
            roles_selected: null,
            services_selected: null,
            access_selected: null,
        },
    }),
    methods: {
        userSubmitData() {
            if(this.$route.query.from_personnel){
                this.$router.push({path:'/admin/business/details',query:{ active_tab: "personnel" }})
            }else{
                this.$router.push({path:'/admin/setting/user-list'})
            }
        },

        displayText(count){
            if (count === 0){
                return ''
            }else if (count === 1){
                return this.user.country[0].name
            }else if(count == this.countrys_options.length){
                this.isAllChecked = true;
                return count + ' Countrys Selected.'
            }else {
                this.isAllChecked = false;
                return  count + ' Countrys Selected.'
            }
        },

        selectAllChanged() {
            if (this.isAllSelected) {
                this.isAllChecked = false;
                this.user.country = [];
                this.countrys_options.map((data)=>{
                    data.isChecked = false
                });
            }else{
                this.isAllChecked = true;
                this.user.country = this.countrys_options.slice();
                this.countrys_options.map((data)=>{
                    data.isChecked = true
                });
            }
        },

        isCheckedInput (actionName) {
            this.countrys_options[actionName.id].isChecked = !this.countrys_options[actionName.id].isChecked
        },
    },computed: {
        isAllSelected: function() {
            return  (this.user.country.length === this.countrys_options.length)
        }
    }
};
</script>
